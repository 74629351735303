import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonCol, IonRow, IonContent } from "@ionic/angular/standalone";
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email',
  standalone: true,
  imports: [IonContent, IonRow, IonCol, IonGrid, IonCardContent, IonCardTitle, IonCardHeader, IonCard,
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule],
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent  implements OnInit {
  constructor (private authService: AuthService, private router:Router){}
  
  ngOnInit() {}


  async Confirm() {
  const user = await this.authService.ReloadAuthFire()
  console.log(user)
  if (user?.emailVerified){
    this.router.navigate(['/CreateAccount']);
  } else {
    alert('Sorry, please verify your email and try again.');
  }
}


}
