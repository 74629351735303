import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../auth/auth.service';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonInput, IonButton, IonGrid, IonCol, IonRow, IonSpinner, IonContent, IonHeader, IonToolbar, IonTitle } from "@ionic/angular/standalone";



@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [IonTitle, IonToolbar, IonHeader, IonContent, IonSpinner, IonRow, IonCol, IonGrid, IonButton, IonInput, IonCardContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, 
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent  implements OnInit {

isLoading = true 

  constructor (private authService: AuthService, private router:Router){}
  SignUpForm!: FormGroup

  ngOnInit(){
    this.SignUpForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email]), 
      password: new FormControl('', [ 
        Validators.required,
        Validators.minLength(8)]), 
    });
    this.isLoading = false
    this.authService.getRedirectResult().then(result => {
      if (result) {
        this.router.navigate(['/Home']);
      } 
    })
    this.authService.ReloadAuthFire()
  }


  async onSubmit(): Promise<void> {
    let isvalid = false
    this.SignUpForm.markAllAsTouched();
  if (this.SignUpForm.valid) {
     this.authService.Register(this.SignUpForm.value.email,this.SignUpForm.value.password )
     .catch((error) => { 
        if (error.code) { 
          return this.SignUpForm.get('email')?.setErrors({ emailTaken: true }); 
        }  
      })
      this.authService.sendEmailVerification()
      
      this.router.navigate(['/VerifyEmail']);
    }
    
  //this.SignUpForm.get('email')!.setErrors({ invalidEmail: true }); 
  
  }




SignUpGoogle() {
this.authService.GoogleAuth()
}
SignUpApple() {
this.authService.AppleAuth()
}
SignUpFacebook() {
this.authService.FacebookAuth()
}
SignUpX() {
this.authService.XAuth()
}
Login() {
  this.router.navigateByUrl("/login")
}

}