import { Component, Inject, Input, signal } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { User } from '../../../Services/models.model';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common'
import { provideNativeDateAdapter, } from '@angular/material/core';

@Component({
  selector: 'app-edit',
  standalone: true,
  providers: [provideNativeDateAdapter(), DatePipe],
  imports: [
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FormsModule,
    MatIconModule
  ],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class UserDialogComponent {
  user!: User;
  @Input() Userdata = [];
  
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DatePipe) private datePipe: DatePipe
  ) {
    if (data) {
      this.user = {
        ...data.element,
        CreatedDate: this.formatDate(data.CreatedDate || new Date().toISOString()),
        UpdatedDate: this.formatDate(data.UpdatedDate || new Date().toISOString()),
        ArchivedDate: this.formatDate(data.ArchivedDate || null),
        LastLoginDate: this.formatDate(data.LastLoginDate || null),
        LastActivityDate: this.formatDate(data.LastActivityDate || null),
        TimeStamp: this.formatDate(data.TimeStamp || new Date().toISOString())
      };
    }
  }

  formatDate(date: Date | null | undefined) {
    if (!date) return null;
    const parsedDate = new Date(date);
    
    return this.datePipe.transform(new Date(date), 'yyyy-MM-ddTHH:mm:ss') ;
  }
  

  hide = signal(true);
  
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.user);
  }
}



