import { Component, OnInit, signal } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from "@ionic/angular/standalone";
import { UserService } from '../../Services/user.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../Services/models.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [IonCardContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, IonContent,  HeaderComponent, DatePipe]
})
export class ProfileComponent  implements OnInit {
  constructor (private userService: UserService, private authService: AuthService, private router: Router, public dialog: MatDialog){

  }
  
   user = signal<any>({})

   ngOnInit(){
    this.authService.getUserData().subscribe()
    this.user = this.authService.UserData
   }
   
  }


