<ion-content>
  <div class="page">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col size="11"  size-sm="10" size-md="8" size-lg="6">
            <ion-card>
              <ion-card-header>
                <ion-card-title>Create your Account</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div id="form">
                <div>
                  <p class="alt-signin"><span>Enter your full name and password</span></p>
                </div>
                <div>
                  <form [formGroup]="CreateAccountForm">
                    <div class="SignUp">
                      <ion-input formControlName="firstName" required label="First Name" labelPlacement="floating" placeholder="" fill="outline" class="SignUp"></ion-input>
                    </div>
                    <div class="SignUp">
                      <ion-input formControlName="lastName" required label="Last Name" labelPlacement="floating" placeholder="" fill="outline" class="SignUp"></ion-input>
                    </div>
                    <div>
                      <ion-input formControlName="username" required label="Username" labelPlacement="floating" placeholder="" fill="outline" class="SignUp" errorText="Username is already taken. Please choose another one."></ion-input>
                    </div>
            <div id="btn">
              <button class="SignUp" color="primary" (click)="CreateAccount()" >
                Create Account
              </button>
            </div>
                  </form>
                </div>
                  
              </div>
              </ion-card-content>
            </ion-card>
        </ion-col>
  </ion-row>
  </ion-grid>
  </div>
</ion-content>