import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './home/UserDashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { RolesComponent } from './home/roles/roles.component';
import { ProductsComponent } from './home/products/products.component';
import { ProfileComponent } from './home/profile/profile.component';
import { adminGuard } from './auth/admin.guard';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EmailComponent } from './email/email.component';
import { CreateAccountComponent } from './create-account/create-account.component';


export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'SignUp', component: SignUpComponent },//, canActivate: [AuthGuard]},
  { path: 'VerifyEmail', component: EmailComponent },
  { path: 'CreateAccount', component: CreateAccountComponent },
  { path: 'Home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'AdminDashboard', component: DashboardComponent, canActivate: [adminGuard]},
  { path: 'Roles', component: RolesComponent, canActivate: [adminGuard]},
  { path: 'Products', component: ProductsComponent, canActivate: [adminGuard]},
  { path: 'Profile', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  
];