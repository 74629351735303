import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, computed, Injectable,NgZone, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppCheck, getToken } from '@angular/fire/app-check';
import {
  Auth,
  deleteUser,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,

  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  User,
  FacebookAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  UserCredential
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { firstValueFrom, from, lastValueFrom, map, Observable, switchMap, take, tap } from 'rxjs';
import { Writable } from 'stream';
import { UserService } from '../Services/user.service';
import { User as DBUser} from '../Services/models.model';
import { environment } from '../../environments/environment';
import { AccountInfo, token } from '../Services/models.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
                  
  private apiUrl = environment.apiUrl;
  public isAdmin = signal(false);
  public UserData = signal<DBUser | {}>({});
  localFireUserData!: any;
  secureBackendToken!: string
  IdToken!: string 
  
  constructor(
    private http: HttpClient, 
    private auth: Auth,
    private router : Router, 
    public ngZone: NgZone, 
    private appCheck: AppCheck,
    
  ){
    onAuthStateChanged(this.auth ,async (user: any)=>{
      if(this.auth.currentUser){
        this.IdToken = await this.auth.currentUser.getIdToken();
        this.secureBackendToken = (await getToken(this.appCheck, /* forceRefresh= */ false)).token;
        this.localFireUserData = user;
        //this.getUserData().subscribe(),
        localStorage.setItem('user', JSON.stringify(this.localFireUserData));
        JSON.parse(localStorage.getItem('user')!);
        
      } else {
        localStorage.clear()
      }
    } );
    
  }
    

    secureBackend = async () => {
      let appCheckTokenResponse;
      try {
        appCheckTokenResponse = await getToken(this.appCheck, /* forceRefresh= */ false);
      } catch (err) {
        return;
      }
      this.secureBackendToken = appCheckTokenResponse.token
      return appCheckTokenResponse.token
    };

     getHeaders(): HttpHeaders {async () =>{
      await this.getId()
      await this.secureBackend() }
      return new HttpHeaders({
        'X-Firebase-AppCheck': this.secureBackendToken,
        'Firebase-Id': this.IdToken 
      });
    }


      getUserData() {
      const result = this.http.get<DBUser>(`${this.apiUrl}/UserData`, { headers:  this.getHeaders() })
      .pipe(
        tap(result =>this.UserData.set(result)),
        map(result => result.RoleId),
        tap(result => {
        if(result === 1){
          this.isAdmin.set(true); 
        } else {
          this.isAdmin.set(false); 
        }    
          
        }),
      );
        return result
    }
       
//get User
async ReloadAuthFire() { 
  if (this.auth.currentUser) { await this.auth.currentUser.reload();
    return this.auth.currentUser; } 
    return null; }
  //get Authenticated user from firebase
  getAuthFire(){
    if (this.auth){
      return this.auth.currentUser;
    } return null
  }
  getId = async () =>{
    if (this.auth.currentUser){
      this.IdToken = await this.auth.currentUser.getIdToken();
      return this.auth.currentUser.getIdToken();

    } return null
    
  }

  //get Authenticated user from Local Storage
  getAuthLocal(){
    const token = localStorage.getItem('user')
    const user = JSON.parse(token as string);
    return user;
  }


  //Check wither User Is looged in or not
  
  isLoggedIn(): boolean {
    if ( this.localFireUserData !== null){
      return this.localFireUserData === this.getAuthFire() ? true : false;
    }
    return false 
  }


  //Register Method
  Register(email : string, password : string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
    .then((result) => {
     this.localFireUserData = result.user;
     this.UserData.set(result.user)
     this.http.post<any>(`${this.apiUrl}/VerifyEmail`, null , { headers: this.getHeaders() }).subscribe();
      this.ngZone.run(() => {
        this.sendEmailVerification()
      });
    })
    
  }

  CheckUserName(UserName: string){
    const CreateAccount = this.http.post<any>(`${this.apiUrl}/CheckUserName`, {UserName} , { headers: this.getHeaders() });
    return CreateAccount

  }


  CreateAccount (AccountInfo: AccountInfo){
    const CreateAccount = this.http.post<any>(`${this.apiUrl}/users/SignUp`, AccountInfo , { headers: this.getHeaders() });
    return CreateAccount
  }


  //Login Method
  Login(email : string, password : string){
    
    return signInWithEmailAndPassword(this.auth, email, password)
    .then((result: UserCredential) => {
      this.localFireUserData = result.user;
      this.ngZone.run(() => {
        this.router.navigate(['/Home']);
      });
    }).then(() => {
       this.getUserData().subscribe()
    })
    
  }
 
 // Delete account 
 DeleteAccount(user: User){
    deleteUser(user).then(() => {
    console.log("User deleted") 
  }).catch((error) => {
    // An error ocurred
    // ...
  });
  
}
 


 //Logout
  Logout() {
    this.UserData.set({})
    this.localFireUserData = null,
    this.secureBackendToken = "",
    this.IdToken = "",
    this.isAdmin.set(false),
    signOut(this.auth).then(()=>this.router.navigate(['/login']))
    

  }

  //Login with Google
  GoogleAuth() {
    return this.loginWithRedirect(new GoogleAuthProvider());
  }
  // Login with Apple
  AppleAuth() {
    return this.loginWithRedirect(new OAuthProvider('apple.com'));
  }
  //Login with Facebook
  FacebookAuth() {
    return this.loginWithRedirect(new FacebookAuthProvider());
  }
  // Login with X
  XAuth() {
    return this.loginWithRedirect(new TwitterAuthProvider());
  }




  loginWithRedirect(provider :any) {
   return signInWithRedirect(this.auth,provider);
  };
   getRedirectResult() {
    return getRedirectResult(this.auth);
  }
 



  //Send Password Reset Email
  async sendPasswordResetEmails(email : string){
     sendPasswordResetEmail(this.auth,email)
     .then(() => {
        window.alert('Password reset email sent, check your inbox.');
     })
     .catch((error) => {
      window.alert(error.message);
    });
  }

  //Send Email Verification
  sendEmailVerification(){
      if(this.auth.currentUser){
        sendEmailVerification(this.auth.currentUser );
      }
  }

}

