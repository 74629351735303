import { Component, OnInit, signal } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { IonContent } from "@ionic/angular/standalone";
import { UserService } from '../../Services/user.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Product, User } from '../../Services/models.model';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import { EditProductComponent } from './EditProducts/editProduct.component';
import { ProductService } from '../../Services/products.service';
@Component({
  selector: 'app-products',
  standalone: true,
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  imports: [IonContent,  HeaderComponent, MatTableModule, DatePipe, MatButtonModule]
})
export class ProductsComponent  implements OnInit {
  displayedColumns: string[] = ['edit', 'ProductId', 'ProductName', 'IsPaid', 'TimeStamp', 'Delete'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  PRODUCT_DATA = signal<Product[]>([]);
  dataSource = new MatTableDataSource<Product>(this.PRODUCT_DATA());

  constructor(
    private productService: ProductService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  async ngOnInit(){
    await this.authService.getId()
    await this.authService.secureBackend() 
    this.productService.getProducts().subscribe((data: Product[]) => {
      this.PRODUCT_DATA.set(data)
      this.dataSource.data = this.PRODUCT_DATA();
    });
  }

  editProduct(element: Product): void {
    const tempdata = JSON.parse(JSON.stringify(element));
    const dialogRef = this.dialog.open(EditProductComponent, {
      width: '1000px',
      data: { element: tempdata }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.productService.updateProduct(element.ProductId.toString(), result).subscribe(
          data => {
            const newProducts = this.PRODUCT_DATA().map(product =>
              product.ProductId === element.ProductId ? data : product
            );
            this.PRODUCT_DATA.set(newProducts);
            this.dataSource.data = this.PRODUCT_DATA();
          }
        );
      }
    });
  }
  DeleteProduct(element: Product) {
    this.productService.deleteProduct(element.ProductId.toString()).subscribe( ()=> {
      this.productService.getProducts().subscribe((data: Product[]) => {
       this.PRODUCT_DATA.set(data)
     });})}
}
