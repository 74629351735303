import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Product } from './models.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient, private  authService:AuthService ) {}
  private apiUrl = environment.apiUrl;
  
  private getHeaders(): HttpHeaders { async () => {
    await this.authService.getId();
    await this.authService.secureBackend()}
    return new HttpHeaders({
      'X-Firebase-AppCheck': this.authService.secureBackendToken,
      'Firebase-Id': this.authService.IdToken
    });
  }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.apiUrl}/products`, { headers: this.getHeaders() });
  }

  createProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(`${this.apiUrl}/products`, product, { headers: this.getHeaders() });
  }

  getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(`${this.apiUrl}/products/${id}`, { headers: this.getHeaders() });
  }

  updateProduct(id: string, product: Product): Observable<Product> {
    return this.http.put<Product>(`${this.apiUrl}/products/${id}`, product, { headers: this.getHeaders() });
  }

  deleteProduct(id: string): Observable<{}> {
    return this.http.delete<{}>(`${this.apiUrl}/products/${id}`, { headers: this.getHeaders() });
  }
}
