import { Component, Inject, Input, signal } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Product } from '../../../Services/models.model';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import {MatDatepickerModule} from '@angular/material/datepicker';
import {provideNativeDateAdapter} from '@angular/material/core';
@Component({
  selector: 'app-editProduct',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [ MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCheckboxModule, FormsModule,MatIconModule ],
  templateUrl: './editProduct.component.html',
  styleUrl: './editProduct.component.scss'
})

export class EditProductComponent {
  @Input() Productdata = [];
  Product!: Product;
  tempProduct!: Product;

  constructor(
    public dialogRef: MatDialogRef<EditProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.Product = data.element;
     
      
    }
  }

  hide = signal(true);

  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {

    this.dialogRef.close(this.Product);
  }
}
