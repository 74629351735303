import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../auth/auth.service';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonInput, IonButton, IonGrid, IonCol, IonRow, IonSpinner, IonContent, IonHeader, IonToolbar, IonTitle } from "@ionic/angular/standalone";




@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [IonContent, IonRow, IonCol, IonGrid, IonInput, IonCardContent, IonCardTitle, IonCardHeader, IonCard,
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule],
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent  implements OnInit {
isLoading: any;

Login() {
throw new Error('Method not implemented.');
}

constructor (private authService: AuthService, private router:Router){}
CreateAccountForm!: FormGroup

ngOnInit(){
  this.CreateAccountForm = new FormGroup({
    firstName: new FormControl(''), 
    lastName: new FormControl(''), 
    username: new FormControl(''), 
  });
}

async CreateAccount() {
  this.authService.CheckUserName(this.CreateAccountForm.value.username).subscribe(res => {
    if (res.Success) {
    this.authService.CreateAccount({
      firstName: this.CreateAccountForm.value.firstName,
      lastName: this.CreateAccountForm.value.lastName,
      Username: this.CreateAccountForm.value.username
    }).subscribe(res => {
      if(res.Email === this.authService.getAuthFire()?.email){
        this.router.navigate(['/Home']);
      }
      else {
        console.log("failed")
      }
    })
  } 
  else {
    this.CreateAccountForm.get('username')!.setErrors({ invalidUsername: true }); 
    
  }

  })

}

}
