<h2 mat-dialog-title>User Info</h2>
<mat-dialog-content>
  <form>
    <mat-form-field>
      <mat-label>UUID</mat-label>
      <input matInput type="text" [(ngModel)]="user.UUID" name="UUID">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput type="email" [(ngModel)]="user.Email" name="Email">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>UserName</mat-label>
      <input matInput type="text" [(ngModel)]="user.UserName" name="UserName">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>FirstName</mat-label>
      <input matInput type="text" [(ngModel)]="user.FirstName" name="FirstName">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>LastName</mat-label>
      <input matInput type="text" [(ngModel)]="user.LastName" name="LastName">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>ProductId</mat-label>
      <input matInput type="text" [(ngModel)]="user.ProductId" name="ProductId">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>RoleId</mat-label>
      <input matInput type="text" [(ngModel)]="user.RoleId" name="RoleId">
    </mat-form-field>
    
    
    
    <mat-form-field>
      <mat-label>CreatedDate</mat-label>
      <input matInput [matDatepicker]="picker1" [(ngModel)]="user.CreatedDate" name="CreatedDate">
      <mat-hint>{{ user.CreatedDate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>ArchivedDate</mat-label>
      <input matInput [matDatepicker]="picker2" [(ngModel)]="user.ArchivedDate" name="ArchivedDate">
      <mat-hint>{{user.ArchivedDate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>LastLoginDate</mat-label>
      <input matInput [matDatepicker]="picker3" [(ngModel)]="user.LastLoginDate" name="LastLoginDate">
      <mat-hint>{{user.LastLoginDate}}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>LastActivityDate</mat-label>
      <input matInput [matDatepicker]="picker4" [(ngModel)]="user.LastActivityDate" name="LastActivityDate">
      <mat-hint>{{user.LastActivityDate}}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
      <mat-datepicker #picker4></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>UserIdentifier</mat-label>
      <input matInput type="text" [(ngModel)]="user.UserIdentifier" name="UserIdentifier">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>TimeStamp</mat-label>
      <input matInput type="datetime-local" [(ngModel)]="user.TimeStamp" name="TimeStamp">
    </mat-form-field>
    
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</mat-dialog-actions>

