import {AfterContentInit, Component, inject, OnInit, signal} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { addIcons } from 'ionicons';
import { exit } from 'ionicons/icons';
import { DashboardComponent } from "./UserDashboard/dashboard.component"
import { IonHeader, IonToolbar, IonButtons, IonContent, IonTitle, IonItem, IonList, IonSplitPane, IonMenu, IonMenuButton, IonIcon, IonLabel} from "@ionic/angular/standalone";
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { UserService } from '../Services/user.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [IonSplitPane, IonList, IonItem, IonTitle, IonContent, IonButtons, IonToolbar, IonHeader, IonMenu, IonMenuButton, IonIcon, IonLabel,
    DashboardComponent, HeaderComponent
],
})
export class HomeComponent {
  isAdmin = signal(false);
  constructor (private authService: AuthService, private router: Router, private userService: UserService){
    addIcons({ exit}); 
    this.isAdmin = this.authService.isAdmin
    
  }


  logout() {
    this.authService.Logout();
    this.router.navigateByUrl('/login');
  }

}
