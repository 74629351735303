<app-header [heading]="'Admin Dashboard'" />

<ion-content>

  <div>
    <button mat-raised-button (click)="getData()"> Get Data </button>
    <button mat-raised-button (click)="addUser()"> Add User </button>
  </div>
  
  <table mat-table [dataSource]="USER_DATA()" class="mat" id="table">
  
      <!-- edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
        <td mat-cell *matCellDef="let element"> 
          <button mat-raised-button (click)="editUser(element)"> Edit User </button> </td>
      </ng-container>
      <!-- id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let element">{{element.UserId}} </td>
      </ng-container>
      <!-- UUID Column -->
      <ng-container matColumnDef="UUID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> UUID </th>
        <td mat-cell *matCellDef="let element"> {{element.UUID}} </td>
      </ng-container>
    
      <!-- Email Column -->
      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
      </ng-container>
    
      <!-- UserName Column -->
      <ng-container matColumnDef="UserName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> UserName </th>
        <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
      </ng-container>
    
      <!-- FirstName Column -->
      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </th>
        <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
      </ng-container>
    
      <!-- LastName Column -->
      <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LastName </th>
        <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
      </ng-container>
    
      <!-- ProductId Column -->
      <ng-container matColumnDef="ProductId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ProductId </th>
        <td mat-cell *matCellDef="let element"> {{element.ProductId}} </td>
      </ng-container>
    
      <!-- RoleId Column -->
      <ng-container matColumnDef="RoleId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> RoleId </th>
        <td mat-cell *matCellDef="let element"> {{element.RoleId}} </td>
      </ng-container>
    
      <!-- CreatedDate Column -->
      <ng-container matColumnDef="CreatedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CreatedDate </th>
        <td mat-cell *matCellDef="let element"> {{element.CreatedDate | date:'short'}} </td>
      </ng-container>
    
      <!-- UpdatedDate Column -->
      <ng-container matColumnDef="UpdatedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> UpdatedDate </th>
        <td mat-cell *matCellDef="let element"> {{element.UpdatedDate | date:'short'}} </td>
      </ng-container>
    
      <!-- ArchivedDate Column -->
      <ng-container matColumnDef="ArchivedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ArchivedDate </th>
        <td mat-cell *matCellDef="let element"> {{element.ArchivedDate | date:'short'}} </td>
      </ng-container>
    
      <!-- LastLoginDate Column -->
      <ng-container matColumnDef="LastLoginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LastLoginDate </th>
        <td mat-cell *matCellDef="let element"> {{element.LastLoginDate | date:'short'}} </td>
      </ng-container>
    
      <!-- LastActivityDate Column -->
      <ng-container matColumnDef="LastActivityDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LastActivityDate </th>
        <td mat-cell *matCellDef="let element"> {{element.LastActivityDate | date:'short'}} </td>
      </ng-container>
    
      <!-- UserIdentifier Column -->
      <ng-container matColumnDef="UserIdentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> UserIdentifier </th>
        <td mat-cell *matCellDef="let element"> {{element.UserIdentifier}} </td>
      </ng-container>
    
      <!-- TimeStamp Column -->
      <ng-container matColumnDef="TimeStamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> TimeStamp </th>
        <td mat-cell *matCellDef="let element"> {{element.TimeStamp | date:'short'}} </td>
      </ng-container>

      <ng-container matColumnDef="Delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
        <td mat-cell *matCellDef="let element"> 
          <button mat-raised-button (click)="DeleteUser(element)"> Delete User </button> </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ion-content>
  