import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Role } from './models.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private apiUrl = environment.apiUrl;

  private getHeaders(): HttpHeaders { async () => {
    await this.authService.getId();
    await this.authService.secureBackend(); }
    return new HttpHeaders({
      'X-Firebase-AppCheck': this.authService.secureBackendToken,
      'Firebase-Id': this.authService.IdToken
    });
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.apiUrl}/roles`, { headers: this.getHeaders() });
  }

  createRole(role: Role): Observable<Role> {
    return this.http.post<Role>(`${this.apiUrl}/roles`, role, { headers: this.getHeaders() });
  }

  getRole(id: number): Observable<Role> {
    return this.http.get<Role>(`${this.apiUrl}/roles/${id}`, { headers: this.getHeaders() });
  }

  updateRole(id: number, role: Role): Observable<Role> {
    return this.http.put<Role>(`${this.apiUrl}/roles/${id}`, role, { headers: this.getHeaders() });
  }

  deleteRole(id: number): Observable<{}> {
    return this.http.delete<{}>(`${this.apiUrl}/roles/${id}`, { headers: this.getHeaders() });
  }
}
