<ion-content>
  <div class="page">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col size="12"  size-sm="8" size-md="6" size-lg="5" sizeXl="4">
          @if (isLoading){
            <ion-spinner name="crescent" color="primary"></ion-spinner>
          } @else {
            <ion-card>
              <ion-card-header>
                <ion-card-title>Surfcasters Sign Up</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div>
                  <p class="alt-signin"><span>Lets get started</span></p>
                </div>
                <div>
                  <form [formGroup]="SignUpForm">
                    <div class="SignUp">
                      <ion-input formControlName="email" type="email" required label="Email" labelPlacement="floating" placeholder="" fill="outline" class="SignUp" errorText="Invalid email, please choose another one or try login."></ion-input>
                    </div>
                    <div>
                      <ion-input formControlName="password" type="password" required label="Password" labelPlacement="floating" placeholder="" fill="outline" class="SignUp" errorText="Invalid Password"></ion-input>
                    </div>
                    <button class="SignUp" color="primary" (click)="onSubmit()" >Continue</button>
                  </form>
                </div>
                <div>
                  <p class="alt-signin"><span>Or sign up with</span></p>
                </div>
                <div class="methods">
                  <a role="button" class="SignUp-methods" (click)="SignUpGoogle()">
                      <span class="method"> 
                        <img src="assets/google.svg" >
                      </span> Google </a>
                  <a role="button" class="SignUp-methods" (click)="SignUpApple()">
                      <span class="method"> 
                        <img src="assets/apple.svg" >
                      </span> Apple </a>
                  <a role="button" class="SignUp-methods" (click)="SignUpFacebook()">
                      <span class="method"> 
                        <img src="assets/facebook.svg" >
                      </span> Facebook </a>
                  <a role="button" class="SignUp-methods" (click)="SignUpX()">
                      <span class="method"> 
                        <img src="assets/x.svg" >
                      </span> X </a>
                </div>
                <div id="signup">
                  <p>Already have an account?</p><a id="signupbtn" role="button" (click)="Login()" > Login</a>
                </div>
              </ion-card-content>
            </ion-card>
          }
        </ion-col>
  </ion-row>
  </ion-grid>
  </div>
</ion-content>