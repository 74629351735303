<h2 mat-dialog-title>Edit Product</h2>
<mat-dialog-content>
  <form>
    <mat-form-field>
      <mat-label>Product Id</mat-label>
      <input matInput type="text" [(ngModel)]="Product.ProductId" name="Product Id">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>Product Name</mat-label>
      <input matInput type="text" [(ngModel)]="Product.ProductName" name="Product Name">
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>IsPayed</mat-label>
      <input matInput type="text" [(ngModel)]="Product.IsPaid" name="IsPayed">
    </mat-form-field>
    
    
    <mat-form-field>
      <mat-label>TimeStamp</mat-label>
      <input matInput [matDatepicker]="picker4" [(ngModel)]="Product.TimeStamp" name="TimeStamp">
      <mat-hint>{{Product.TimeStamp}}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
      <mat-datepicker #picker4></mat-datepicker>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</mat-dialog-actions>

