import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../auth/auth.service';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonInput, IonButton, IonGrid, IonCol, IonRow, IonSpinner, IonContent, IonHeader, IonToolbar, IonTitle, IonFooter } from "@ionic/angular/standalone";
import { OAuthProvider } from 'firebase/auth';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [IonFooter, IonTitle, IonToolbar, IonHeader, IonContent, IonSpinner, IonRow, IonCol, IonGrid, IonButton, IonInput, IonCardContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, 
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  isLoading = true 
  
  constructor (private authService: AuthService, private router:Router){}
  loginForm!: FormGroup
  
  ngOnInit(){
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email]), 
      password: new FormControl('', [ 
        Validators.required,
        Validators.minLength(8)]), 
    });
    this.isLoading = false
    this.authService.getRedirectResult().then(result => {
      if (result) {
        this.router.navigate(['/dash']);
      } 
    })
  }
  
  
  onSubmit(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
       this.authService.Login(this.loginForm.value.email,this.loginForm.value.password )
       .catch((error) => { 
        if (error.code) { 
          return this.loginForm.get('email')?.setErrors({ emailInvalid: true }); 
        }  
      })
  
    }
    } 
    
  
      
      loginGoogle() {
        this.authService.GoogleAuth()
      }
      loginApple() {
        this.authService.AppleAuth()
      }
      loginFacebook() {
        this.authService.FacebookAuth()
      }
      loginX() {
        this.authService.XAuth()
      }
      SignUp() {
      this.router.navigateByUrl("/SignUp")
      }
      
    }
