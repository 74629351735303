import { afterNextRender, ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, NgZone, OnInit, signal, Signal} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { IonRouterOutlet, IonApp, IonContent, IonLabel, IonItem, IonList, IonTitle, IonToolbar, IonHeader, IonSplitPane, IonMenu, IonMenuToggle} from "@ionic/angular/standalone";
import { AuthService } from './auth/auth.service';
import { LoginComponent } from './login/login.component';
import { UserService } from './Services/user.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EmailComponent } from './email/email.component';
import { CreateAccountComponent } from './create-account/create-account.component';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [IonSplitPane, IonHeader, IonToolbar, IonTitle, IonList, IonItem, IonLabel, IonContent, IonApp, IonRouterOutlet, RouterOutlet, IonMenu, IonMenuToggle],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  
})

export class AppComponent implements OnInit{
  title = 'surfcasterslog';
  public SplitPane = false;
  isAdmin = signal(false);
  
  constructor(public authService: AuthService,public userService: UserService, private router: Router, private ngZone:NgZone,  private cdr: ChangeDetectorRef) {
    this.isAdmin = this.authService.isAdmin;  
  
  }

  async ngOnInit() {
   
    //await this.authService.getId()
    // await this.authService.secureBackend()
  }

  
  showSplitPane(component: any) {
    if(component instanceof LoginComponent || 
       component instanceof SignUpComponent || 
       component instanceof EmailComponent ||
       component instanceof CreateAccountComponent) {
      this.SplitPane = false;
    } else {
      this.SplitPane = true;
    }
  }
  
  goHome() {
    this.router.navigateByUrl('/Home');
  }
  Admin() {
    this.router.navigateByUrl('/AdminDashboard');
    }
  Products() {
  this.router.navigateByUrl('/Products');
  }
  Roles() {
  this.router.navigateByUrl('/Roles');
  }
  Profile() {
  this.router.navigateByUrl('/Profile');
  }
  logout() {
    this.authService.Logout();
    this.router.navigateByUrl('/login');
  }


}
