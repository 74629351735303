
<ion-app>
  
  @if (SplitPane) {
  <ion-split-pane when="md" contentId="main-content">
    <ion-menu side="start"content-id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" (click)="goHome()" button>
              <ion-label>Home </ion-label>
            </ion-item>
          </ion-menu-toggle>
          @if (isAdmin()) {
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" (click)="Admin()" button>
                <ion-label>Admin Dashboard </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" (click)="Products()" button>
                <ion-label>Product Table</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" (click)="Roles()" button>
                <ion-label>Role Table</ion-label>
              </ion-item>
            </ion-menu-toggle>
          }
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" (click)="Profile()" button>
              <ion-label>Profile</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" (click)="logout()" button>
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <div class="ion-page" id="main-content">
      <ion-content>
        <ion-router-outlet
          (activate)="showSplitPane($event)"
        ></ion-router-outlet>
      </ion-content>
    </div>
  </ion-split-pane>
  } @else {
  <ion-content>
    <ion-router-outlet (activate)="showSplitPane($event)"></ion-router-outlet>
  </ion-content>
  }
</ion-app>

