import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAuth,getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { getApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { provideAppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider } from '@angular/fire/app-check';

import { AppComponent } from './app.component';
import { provideIonicAngular } from '@ionic/angular/standalone';



export const appConfig: ApplicationConfig = {
  providers: [
    
    provideHttpClient(withFetch()),
    provideRouter(routes), 
    provideAnimationsAsync(),
    provideZoneChangeDetection(), 
    provideRouter(routes), 
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAppCheck(() => initializeAppCheck(getApp(), {
      provider: new ReCaptchaEnterpriseProvider("6LcCsU8qAAAAAIOvUA4SvFlYIVvGEQ0tEdU6yyCY"),
      isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
    })),
    
    provideAuth(() => {
      const auth = getAuth();
      //connectAuthEmulator(auth, 'http://localhost:2000', { disableWarnings: true });
      return auth;
    }), provideIonicAngular({}),
  ]
};
