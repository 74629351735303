import { Injectable, Signal, signal } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from './models.model';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) {}

  UserData = signal<User | {}>({})
  private getHeaders(): HttpHeaders {async () =>{
    await this.authService.getId()
    await this.authService.secureBackend() }
    return new HttpHeaders({
      'X-Firebase-AppCheck': this.authService.secureBackendToken,
      'Firebase-Id': this.authService.IdToken
    });
  }
  

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}/users`, { headers: this.getHeaders() });
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/users`, user, { headers: this.getHeaders() });
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/users/${id}`, { headers: this.getHeaders() });
  }

  updateUser(id: string, user: User): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/users/${id}`, user, { headers: this.getHeaders() });
  }

  deleteUser(id: string): Observable<{}> {
    return this.http.delete<{}>(`${this.apiUrl}/users/${id}`, { headers: this.getHeaders(),  observe: 'response' });
  }
  

}
