import {Component, signal, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import { UserService } from '../../Services/user.service';
import { OnInit } from '@angular/core'
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogComponent } from '../UserDashboard/EditUsers/edit.component';
import { HomeComponent } from "../home.component";
import { tap } from 'rxjs';
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonMenuButton, IonContent } from "@ionic/angular/standalone";
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { User } from '../../Services/models.model';
import { HeaderComponent } from '../header/header.component';
@Component({
  selector: 'DashboardComponent',
  styleUrl: 'dashboard.component.scss',
  templateUrl: 'dashboard.component.html',
  standalone: true,
  imports: [IonContent, IonTitle, IonButtons, IonToolbar, IonHeader, IonMenuButton, MatButtonModule, MatTableModule, MatTable, DatePipe, HeaderComponent, ],
})
export class DashboardComponent {
  displayedColumns: string[] = ['edit','id','UUID', 'Email', 'UserName', 'FirstName', 'LastName', 'ProductId', 'RoleId', 'CreatedDate', 'UpdatedDate', 'ArchivedDate', 'LastLoginDate', 'LastActivityDate', 'UserIdentifier', 'TimeStamp', 'Delete'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  constructor (private userService: UserService, private authService: AuthService, private router: Router, public dialog: MatDialog){
  }
  
  edit!: string;
  USER_DATA = signal<User[]>([]);
  dataSource = new MatTableDataSource<User>(this.USER_DATA());

   async ngOnInit(){
    await this.authService.getId()
    await this.authService.secureBackend() 
     this.userService.getUsers().subscribe((data: User[]) => {
      this.USER_DATA.set(data)
   });
   
  }
  
   getData() {
     this.userService.getUsers().subscribe((data: User[]) => {
      this.USER_DATA.set(data)
      
    });
    
  }
  
  logout() {
    this.authService.Logout();
    this.router.navigateByUrl('/login');
  }
  
  
  addUser(): void {
  
    const data = JSON.parse(JSON.stringify({
      UserIdentifier: '',
      Email: '',
      UserName: '',
      FirstName: '',
      LastName: '',
      ProductId: '1',
      RoleId: '1',
      CreatedDate:  '', 
      UpdatedDate: '',
      ArchivedDate: '',
      LastLoginDate: '',
      LastActivityDate: '',
      TimeStamp: ''
    }));
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '1000px',
      data: { element: data }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.createUser(result).subscribe(data => {
          this.USER_DATA.update(users => [...users, data]);
        });
      }
    });
  }
  

  editUser(element:User): void {
    const tempdata = JSON.parse(JSON.stringify(element));
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '1000px',
      data: { element: tempdata }
    });
    
    dialogRef.afterClosed().subscribe( result => {
      
      if(result){
         this.userService.updateUser(element.UserId.toString(), result).subscribe(
          data => {
            const newUsers = this.USER_DATA().map((user) => 
              {if (user.UUID === element.UUID){ return  data } return user})
          this.USER_DATA.set(newUsers)
      })
      }
    });
    
  }

  
   DeleteUser(element: User) {
   this.userService.deleteUser(element.UserId.toString()).subscribe( (res: any)=> {
     this.userService.getUsers().subscribe((data: User[]) => {
      this.USER_DATA.set(data)
    });
    if (res.status === 200) {
      alert('User deleted successfully.');

    }
  });
  }
}


