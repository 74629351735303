<ion-content>
  <div class="page">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col size="12"  size-sm="8" size-md="6" size-lg="5" sizeXl="4">
          @if (isLoading){
            <ion-spinner name="crescent" color="primary"></ion-spinner>
          } @else {
            <ion-card>
              <ion-card-header>
                <ion-card-title>Surfcasters Login</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                
                <div>
                  <form [formGroup]="loginForm">
                    <div class="login">
                      <ion-input formControlName="email" required label="Email" labelPlacement="floating" placeholder="" fill="outline" class="login" errorText="Invalid email, please try again."></ion-input>
                    </div>
                    <div>
                      <ion-input formControlName="password" type="password" required label="Password" labelPlacement="floating" placeholder="" fill="outline" class="login" errorText="Invalid email/password combination"></ion-input>
                    </div>
            
                    <button class="login" color="primary" (click)="onSubmit()" >
                      Login
                    </button>
                    
                    
                  </form>
                </div>
                <div>
                  <p class="alt-signin"><span>Or sign in with</span></p>
                </div>
                <div class="methods">
                  <a role="button" class="login-methods" (click)="loginGoogle()">
                      <span class="method"> 
                        <img src="assets/google.svg" >
                      </span> Google </a>
                  <a role="button" class="login-methods" (click)="loginApple()">
                      <span class="method"> 
                        <img src="assets/apple.svg" >
                      </span> Apple </a>
                  <a role="button" class="login-methods" (click)="loginFacebook()">
                      <span class="method"> 
                        <img src="assets/facebook.svg" >
                      </span> Facebook </a>
                  <a role="button" class="login-methods" (click)="loginX()">
                      <span class="method"> 
                        <img src="assets/x.svg" >
                      </span> X </a>
                </div>
                <div id="signup">
                  <p>New to Surfcasters Log?</p><a id="signupbtn" role="button" (click)="SignUp()" > Sign Up</a>
                </div>
              </ion-card-content>
            </ion-card>
          }
        </ion-col>
  </ion-row>
  </ion-grid>
  </div>
</ion-content>


 