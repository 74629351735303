<ion-content>
  <div class="page">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col size="12"  size-sm="11" size-md="10" >
            <ion-card>
              <ion-card-header>
                <ion-card-title>Please verify your Email</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div id="verify">
                  <p>Check your emails and follow the link to verify your account.</p>
                </div>
                <div id="confirm">
                  <button class="Verify" color="primary" (click)="Confirm()" >
                    Confirm
                  </button>
                </div>
              </ion-card-content>
            </ion-card>
        </ion-col>
  </ion-row>
  </ion-grid>
  </div>
</ion-content>