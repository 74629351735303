<app-header [heading]="'Roles Dashboard'" />
<ion-content>
  <table mat-table [dataSource]="ROLE_DATA()" class="mat" id="table">
  
    <!-- edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-raised-button (click)="editRole(element)"> Edit Role </button> </td>
    </ng-container>
    <!-- RoleId Column -->
    <ng-container matColumnDef="RoleId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role Id </th>
      <td mat-cell *matCellDef="let element">{{element.RoleId}} </td>
    </ng-container>
    <!-- RoleDescription Column -->
    <ng-container matColumnDef="RoleDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role Description </th>
      <td mat-cell *matCellDef="let element"> {{element.RoleDescription}} </td>
    </ng-container>
    <!-- IsAdmin Column -->
    <ng-container matColumnDef="IsAdmin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Admin </th>
      <td mat-cell *matCellDef="let element"> {{element.IsAdmin}} </td>
    </ng-container>
    <!-- TimeStamp Column -->
    <ng-container matColumnDef="TimeStamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TimeStamp </th>
      <td mat-cell *matCellDef="let element"> {{element.TimeStamp | date:'short'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-raised-button (click)="DeleteRole(element)"> Delete Role </button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ion-content>
