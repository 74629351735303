export const environment = {
    production: false, 
    apiUrl: 'https://server.dev.surfcasterslog.com',
    //apiUrl: 'http://localhost:2800',
    firebase: {
        apiKey: "AIzaSyCxHRd_3WxcLhdcn67mhgjGTgk0Zby30AA",
        authDomain: "surfcasterslog.firebaseapp.com",
        databaseURL: "https://surfcasterslog-default-rtdb.firebaseio.com",
        projectId: "surfcasterslog",
        storageBucket: "surfcasterslog.appspot.com",
        messagingSenderId: "182584369116",
        appId: "1:182584369116:web:47c77dcf0ca7e47b65f919",
        measurementId: "G-WN22JPW7QB"
    }
  };