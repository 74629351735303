<app-header [heading]="'Products Dashboard'" />
<ion-content>
  <table mat-table [dataSource]="PRODUCT_DATA()" class="mat" id="table">
  
    <!-- edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-raised-button (click)="editProduct(element)"> Edit Product </button> </td>
    </ng-container>
    <!-- id Column -->
    <ng-container matColumnDef="ProductId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Id </th>
      <td mat-cell *matCellDef="let element">{{element.ProductId}} </td>
    </ng-container>
    <!-- ProductName Column -->
    <ng-container matColumnDef="ProductName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
      <td mat-cell *matCellDef="let element"> {{element.ProductName}} </td>
    </ng-container>
    <!-- IsPaid Column -->
    <ng-container matColumnDef="IsPaid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Paid </th>
      <td mat-cell *matCellDef="let element"> {{element.IsPaid}} </td>
    </ng-container>
    <!-- TimeStamp Column -->
    <ng-container matColumnDef="TimeStamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TimeStamp </th>
      <td mat-cell *matCellDef="let element"> {{element.TimeStamp | date:'short'}} </td>
    </ng-container>

    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-raised-button (click)="DeleteProduct(element)"> Delete Product </button> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ion-content>



