<app-header [heading]="'My Profile'" />
<ion-content>

  <ion-card>
    <ion-card-header>
      <ion-card-title>{{ user().UserName }}</ion-card-title>
      <ion-card-subtitle>{{ user().Email }}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <p><strong>First Name:</strong> {{ user().FirstName }}</p>
      <p><strong>Last Name:</strong> {{ user().LastName }}</p>
      <p><strong>UUID:</strong> {{ user().UUID }}</p>
      <p><strong>Product ID:</strong> {{ user().ProductId }}</p>
      <p><strong>Role ID:</strong> {{ user().RoleId }}</p>
      <p><strong>Created Date:</strong> {{ user().CreatedDate | date }}</p>
      <p><strong>Updated Date:</strong> {{ user().UpdatedDate | date }}</p>
      <p><strong>Archived Date:</strong> {{ user().ArchivedDate | date }}</p>
      <p><strong>Last Login Date:</strong> {{ user().LastLoginDate | date }}</p>
      <p><strong>Last Activity Date:</strong> {{ user().LastActivityDate | date }}</p>
      <p><strong>User Identifier:</strong> {{ user().UserIdentifier }}</p>
      <p><strong>Timestamp:</strong> {{ user().TimeStamp | date }}</p>
    </ion-card-content>
  </ion-card>
</ion-content>
