import { Component, OnInit, signal } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { IonContent } from "@ionic/angular/standalone";
import { RoleService } from '../../Services/roles.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Role } from '../../Services/models.model';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { EditRolesComponent } from './edit-roles/edit-roles.component';

@Component({
  selector: 'app-roles',
  standalone: true,
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  imports: [IonContent, HeaderComponent, MatTableModule, DatePipe, MatButtonModule]
})
export class RolesComponent implements OnInit {
  displayedColumns: string[] = ['edit', 'RoleId', 'RoleDescription', 'IsAdmin', 'TimeStamp', 'Delete'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  constructor(private roleService: RoleService, private authService: AuthService, private router: Router, public dialog: MatDialog) {}

  edit!: string;
  ROLE_DATA = signal<Role[]>([]);
  dataSource = new MatTableDataSource<Role>(this.ROLE_DATA());

  async ngOnInit(){
    await this.authService.getId()
    await this.authService.secureBackend() 
    
    this.roleService.getRoles().subscribe((data: Role[]) => {
      this.ROLE_DATA.set(data);
      
    });
  }

  editRole(element: Role): void {
    const tempdata = JSON.parse(JSON.stringify(element));
    const dialogRef = this.dialog.open(EditRolesComponent, {
      width: '1000px',
      data: { element: tempdata }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.roleService.updateRole(element.RoleId, result).subscribe(data => {
          const newRoles = this.ROLE_DATA().map(role => {
            if (role.RoleId === element.RoleId) {
              return data;
            }
            return role;
          });
          this.ROLE_DATA.set(newRoles);
        });
      }
    });
  }
  DeleteRole(element: Role) {
    this.roleService.deleteRole(element.RoleId).subscribe( ()=> {
      this.roleService.getRoles().subscribe((data: Role[]) => {
       this.ROLE_DATA.set( data)
     });})}
}
